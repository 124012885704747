import React, { useEffect, useState } from 'react';
import { withGraphql } from 'gatsby-source-graphql-universal';
import qs from 'query-string';
import ProjectPage from '../components/Projects/ProjectPage/ProjectPage';
import NewsPage from '../components/News/NewsPage';
import PrimoPianoCard from '../components/Carousel/PrimoPianoCard';
import ComingSoonCard from '../components/Carousel/ComingSoonCard';

export const query = `
  query ResidenzePreviewQuery($id: ID!) {
    wpgraphql {
      residenza(id: $id) {
        gallery {
          full_image_url
        }
        planimetrie {
          caption
          full_image_url
        }
        revisions(first: 1) {
          nodes {
            id
            acfResidenza {
              address {
                city
                country
                latitude
                longitude
                postCode
                streetNumber
                streetAddress
                streetName
                state
              }
              apartments
              zone
              booking
              class
              description
              imagepreview {
                sourceUrl(size: LARGE)
              }
              logo {
                sourceUrl(size: LARGE)
              }
              mainimage {
                sourceUrl
              }
              name
              seourl
              price
              hideprice
              rating
              soldout
              title
              subdescription
              labelavailableapt
              apartmentstype
              available
              zonedescription
              siteurl
              video
              groupId
              projname
              projectId
              buildingId
            }
          }
        }
      }
      news(id: $id) {
        revisions(first: 1) {
          nodes {
            id
            acfNews {
              url
              mainimage {
                sourceUrl(size: TWENTYTWENTY_FULLSCREEN)
              }
              date
              source
              content
              category
              articolopdf {
                mediaItemUrl
              }
              description
              title
            }
          }
        }
      }
      residenzaComingSoon(id: $id) {
        revisions(first: 1) {
          nodes {
            acfResidenzaComingSoon {
              description
              place
              residenza {
                ... on WPGraphQL_Residenza {
                  id
                }
              }
              title
              image {
                sourceUrl
              }
            }                    
          }
        }
      }
      residenzaPrimoPiano(id: $id) {
        revisions(first: 1){
          nodes {
            acfResidenzaPrimoPiano {
              title
              order
              description
              residenza {
                ... on WPGraphQL_Residenza {
                  id
                  acfResidenza {
                    name
                    zone
                  }
                }
              }
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }`;

function Preview({ graphql }) {
  const [post, setPost] = useState(null);

  const [postType, setPostType] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      const { id, type, wpnonce } = qs.parse(window.location.search);
      const context = {
        headers: {
          'X-WP-Nonce': wpnonce,
        },
        credentials: 'include',
      };
      try {
        const { data } = await graphql('wpgraphql', {
          query,
          context,
          variables: {
            id: btoa(`post:${id}`)
          }
        });
        console.log(data);
        if (type === 'news') {
          setPost(data.news.revisions.nodes[0]);
        } else if (type === 'residenze') {
          const post = data.residenza.revisions.nodes[0];
          post.gallery = data.residenza.gallery;
          post.planimetrie = data.residenza.planimetrie;

          setPost(post);
        } else if (type === 'res_coming_soon') {
          setPost(data.residenzaComingSoon.revisions.nodes[0]);
        } else if (type === 'res_primo_piano') {
          setPost(data.residenzaPrimoPiano.revisions.nodes[0]);
        }
        setPostType(type);
        setLoading(false);
      } catch (error) {
        setError(error);
        throw Error(error);
      }
    };
    fetchPost();
  }, [graphql]);

  if (error !== null) {
    return <span>{error.message}</span>;
  }
  if (loading) {
    return <span>Loading...</span>;
  }
  return (postType === 'residenze' ?
    <ProjectPage project={post} isPreview={true}></ProjectPage>
    :
    (postType === 'news' ?
      <NewsPage articleItem={post} isPreview={true} />
      :
      (postType === 'res_primo_piano' ?
        <PrimoPianoCard item={post.acfResidenzaPrimoPiano} isCurrentSlide={true} isPreview={true} />
        :
        (postType === 'res_coming_soon' ?
          <ComingSoonCard item={post.acfResidenzaComingSoon} isCurrentSlide={true} isPreview={true} />
          : null))));
}

export default withGraphql(Preview);